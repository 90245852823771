




























import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { IdoPoolDetailViewModel } from '../viewmodels/ido-pool-detail-viewmodel'

@Observer
@Component({
  components: {
    HoverImage: () => import('@/modules/market/components/hover-image.vue'),
  },
})
export default class InvestSuccessDialog extends Vue {
  @Inject() vm!: IdoPoolDetailViewModel
}
